import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div><h1>TinyDesbois.fr</h1></div>
      </header>
      <main className="App-main">
        <div className="bloc-lien">
          <a href="/diablo-manager">
            <div>Diablo Manager</div>
          </a>
        </div>
      </main>
    </div>
  );
}

/*
<div className="bloc-lien">
<a href="/my-react-game">
    <div>My game</div>
  </a>
</div>
*/

export default App;
